import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { colors, spacing } from '../../styles/variables'
import LightBoxGallery from '../LightboxGallery'
import SectionHeading from '../SectionHeading'

const Container = styled.div`
  min-height: 100vh;

  background-color: ${colors.white};

  & > .gallery-wrapper {
    padding-top: ${spacing.size09};

    display: flex;
    justify-content: center;
  }
`

const query = graphql`
  query GetGalleryImages {
    allFile(filter: { relativeDirectory: { eq: "gallery" }, extension: { regex: "/(jpg)|(png)/" } }) {
      nodes {
        id
        absolutePath
        childImageSharp {
          id
          fluid {
            src
            srcSet
            aspectRatio
          }
        }
      }
    }
  }
`

function Gallery() {
  const data = useStaticQuery(query)

  return (
    <Container>
      <SectionHeading text="Galerie" color={colors.main} />
      <div className="gallery-wrapper">
        <LightBoxGallery images={data.allFile.nodes} />
      </div>
    </Container>
  )
}

export default Gallery
